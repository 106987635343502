const ListIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.33301 4H13.9997"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 8H13.9997"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 12H13.9997"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 4H2.00667"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8H2.00667"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12H2.00667"
      stroke="#222222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ListIcon;
